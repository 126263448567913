<template>
  <div class="animated fadeIn col-xl-10 offset-md-1">
    <b-card header-tag="header">
      <div slot="header">
        <i class="icon-list"></i> Premium
      </div>
      <div v-if="loading">
        <div class="sk-circle-fade float-left">
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
        </div>
        <h2 class="float-left ml-4">Loading premium...</h2>
      </div>
      <div v-else>
        <div v-if="!is_patron">
          <h4 class="subtitle">Become a patron</h4>
          <p>Hey there, this feature is only available to patrons.</p>

          <a href="https://www.patreon.com/join/carlbot">
            <img
              alt="become a patron"
              src="@/../public/img/brand/BecomeAPatron.png"
            />
          </a>
          <p>For $5/month you gain access to:</p>
          <ul>
            <li>Levels</li>
            <li>Timed reaction roles</li>
            <li>Custom messages (with embeds!) for moderation actions</li>
            <li>1000 reaction role limit</li>
            <li>Immediate autofeeds</li>
            <li>Role mention toggle in short autofeeds</li>
            <li>Higher purge limits</li>
            <li>Voice-role links</li>
            <li>Multiple command blocks in a single tag</li>
            <li>Audit log fetching for bans in the modlog</li>
            <li>Autostar for starboard</li>
            <li>Your own role in the support server</li>
            <li>Access to new commands and features before the general public</li>
            <li>Last but not least: You're directly responsible for keeping the bot alive for tens of thousands of servers</li>
          </ul>
        </div>
        <div v-else>
          <h4 class="subtitle">Hello there, patron</h4>
          <p>On this page you can manage your premium servers. Your tier makes you eligible for {{tier}} boost{{tier !== 1 ? 's' : ''}} and you have {{boosts_left}} boost{{boosts_left !== 1 ? 's' : ''}} left.</p>
          <b-row align-h="center">
            <div v-for="g in sorted_guilds" :key="g.id" class="row">
              <b-col class="m-2 center">
                <img
                  :src="g.icon"
                  class
                  alt
                  :style="get_style(g)"
                  height="128"
                  width="128"
                  :title="g.link ? 'Not yet invited, click to invite' : ''"
                  style="cursor: pointer; border-radius: 50%"
                  @click="open_modal(g)"
                />
                <p class="text-center">{{g.name}}</p>
              </b-col>
            </div>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-modal :title="modaltitle" size="md" v-model="modal" :no-enforce-focus="true">
      <div v-if="selected_guild.vip">
        <p>Click the button below to remove premium from this server. Please keep in mind this removes any temporary reaction roles.</p>
      </div>
      <div v-else>
        <p>Press the button bellow to immediately gain access to all premium features.</p>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="modal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                v-if="selected_guild.vip"
                variant="danger"
                v-on:click="remove_premium"
              >Remove</b-button>
              <b-button v-else variant="success" v-on:click="add_premium">Mark as premium</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);

export default {
  name: "ServerPicker",
  components: {},
  data: function() {
    return {
      is_patron: false,
      vip_servers: [],
      tier: 0,
      boosts_left: 0,
      selected_guild: {},
      modal: false,
      loading: true
    };
  },
  notifications: {
    alert: {
      type: VueNotifications.types.warn,
      title: "Sorry!",
      message: "You do not have any boosts left"
    },
    success: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "You will be redirected to your server's dashboard in a second"
    }
  },
  methods: {
    get_style(g) {
      if (g.link == undefined && g.vip) {
        return "border: 3px solid #ff675a";
      }
      return g.link == undefined
        ? "border: 3px solid #6AFFAA;"
        : "border: 3px solid #6AFFAA; -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -ms-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%);";
    },
    open_modal(g) {
      if (!g.vip && this.boosts_left == 0) {
        return this.alert();
      }
      this.selected_guild = g;
      this.modal = true;
    },
    add_premium() {
      if (!this.selected_guild.id) {
        return this.alert({
          message:
            "Not sure how you ended up in this situation but I don't know what server you want to boost."
        });
      }
      return this.axios
        .post("/api/v1/servers/" + this.selected_guild.id + "/patron", {})
        .then(r => {
          this.success();
          this.$store.commit("toggle_premium", this.selected_guild.id);
          setTimeout(() => {
            this.$router.push(
              "/dashboard/" + this.selected_guild.id + "/botsettings"
            );
          }, 1000);
        });
    },
    remove_premium() {
      if (!this.selected_guild.id) {
        return this.alert({
          message:
            "Not sure how you ended up in this situation but I don't know what server you want to boost."
        });
      }
      return this.axios
        .delete("/api/v1/servers/" + this.selected_guild.id + "/patron")
        .then(r => {
          this.success();
          this.$store.commit("toggle_premium", this.selected_guild.id);
          setTimeout(() => {
            this.$router.push(
              "/dashboard/" + this.selected_guild.id + "/botsettings"
            );
          }, 1000);
        });
    }
  },

  computed: {
    sorted_guilds() {
      return this.$store.state.discord_guilds
        .filter(g => !g.link)
        .sort(
          (a, b) =>
            (a.link || "").length - (b.link || "").length || a.name > b.name
        );
    },
    modaltitle() {
      return this.selected_guild.vip
        ? "Remove Premium From Server " + this.selected_guild.name
        : "Add Premium to Server " + this.selected_guild.name;
    }
  },
  created: function() {
    this.axios.get("/api/v1/users/@me/premium").then(r => {
      this.loading = false;
      this.vip_servers = r.data.servers;
      this.is_patron = r.data.is_patron;
      this.tier = r.data.tier;
      this.boosts_left = r.data.tier - r.data.servers.length;
    });
  }
};
</script>
<style src="spinkit/spinkit.min.css"></style>

